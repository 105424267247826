
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import formatDate from '@/modules/common/filters/format-date.filter';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import MarketsDocumentModel from '../models/markets-document.model';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class MarketsDayScanBtn extends Vue {
    @inject(MarketsServiceS) marketsService!: MarketsService;
    @inject(ClusterServiceS) clusterService!: ClusterService;
    @inject(MarketsCommonServiceS) marketsCommonService!: MarketsCommonService;
    @inject(ClusterMarketsServiceS) clusterMarketsService!: ClusterMarketsService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day | undefined;

    @Prop({
        type: Object,
    })
    private settings!: {
        compsetId: string | null;
        provider: string | null;
        los: number | null;
        pos: string | null;
        // If hoteId is undefined, this is hotel level
        hotelId?: number;
    };

    get document() {
        if (!this.settings.provider) {
            return null;
        }

        if (this.settings.hotelId) {
            return this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(this.settings.hotelId) as unknown as MarketsDocumentModel;
        }

        return this.marketsService.getDocument(this.settings.provider);
    }

    get isScanning() {
        if (!this.document) {
            return false;
        }

        return this.marketsCommonService.isOnDemandRunning(this.document) === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return !this.marketsCommonService.isScanAvailable(this.day) || !this.settings.provider;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }

        if (this.settings.hotelId) {
            await this.clusterMarketsService.triggerScan(this.settings, this.day);
        } else {
            await this.marketsService.triggerScan(this.settings, this.day);
        }
    }

    get formatScanDate() {
        const dateScan = this.marketsCommonService.dayUpdateDate(this.day || 1, this.document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }
}
